<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width=" ">
        <el-form-item label="商品名称">
          <el-input v-model="searchData.goodsTitle" size="small" placeholder="请输入商品名称"></el-input>
        </el-form-item>
        <el-form-item label="商品分类">
          <SelectGoodsCategory v-model="searchData.categoryId"></SelectGoodsCategory>
        </el-form-item>
        <el-form-item label="首页推荐">
          <el-select v-model="searchData.isRecommend" placeholder="请选择" size="small" clearable>
            <el-option label="是" :value="1"> </el-option>
            <el-option label="否" :value="0"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getGoodsList">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="header-tabs">
      <el-radio-group v-model="searchData.status" size="small" @input="getGoodsList">
        <el-radio-button :label="null">全部</el-radio-button>
        <el-radio-button :label="1">已上架</el-radio-button>
        <el-radio-button :label="0">已下架</el-radio-button>
      </el-radio-group>
      <el-button
        v-checkbtn="isShowBtn(AUTH_BTN.goods_list_add)"
        style="margin-left:20px"
        type="primary"
        size="small"
        icon="el-icon-plus"
        @click="$router.push({ name: 'GoodsAdd' })"
        >新增
      </el-button>
    </div>
    <el-divider></el-divider>

    <tp-table
        ref="goodsTable"
        :tableData="list"
        :columns="columns"
        :totalNum="total"
        :current-page.sync="currentPage"
        :pageSize.sync="pageSize"
        @handleSortChange="handleSortChange"/>

    <!-- 权重配置 -->
    <el-dialog title="权重配置" :visible.sync="dialogWeight" class="weight-dialog" width="800px">
      <el-form ref="formWeight" :model="goodsInfo" label-width="130px">
        <el-form-item label="当前权重：">{{ goodsInfo.weight }}</el-form-item>
        <el-form-item label="权重设置：">
          <el-input-number v-model="goodsInfo.newWeight" size="small" placeholder="请输入" :min="1" :max="999" style="width:200px;"></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer" style="margin-top:20px;">
        <el-button @click="dialogWeight = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="submitWeight" size="mini">提 交</el-button>
      </div>
    </el-dialog>

    <div style="height:60px;"></div>
  </div>
</template>

<script>
import {
  getGoodsListAPI,
  deleteGoodsAPI,
  changeStatusAPI,
  addGoodsStockAPI,
  changeRecommendAPI,
  changeSyncChannelAPI,
  changeWeightAPI,
} from './api'
import SelectGoodsCategory from '@/views/components/select-goods-category.vue'
import { authBtnMixin } from '@/mixins/authBtnMixin'
const columns = [
  {
    label: 'ID',
    prop: 'id',
    minWidth: '80',
    customRender(h, row) {
      return <p>{row['id']}</p>
    }
  },
  {
    label: '商品分类',
    prop: 'category',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['category']?.['categoryName']}</p>
    }
  },
  {
    label: '品牌',
    prop: 'brand',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['brandName']}</p>
    }
  },
  {
    label: '商品名称',
    prop: 'goodsTitle',
    minWidth: '200',
    customRender(h, row) {
      return (
        <div>
          <p>{row['goodsTitle']}</p>
          <p>{row['subTitle']}</p>
        </div>
      )
    }
  },
  {
    label: '套餐类型',
    prop: 'stockNum',
    minWidth: '120',
    customRender(h, row) {
      return (
        <div>
          {row['goodsComboEnumList'].map(item => {
            return (
              <div>
                <el-tag type="primary" size="mini">
                  {item.enumText}
                </el-tag>
              </div>
            )
          })}
        </div>
      )
    }
  },
  {
    label: '库存',
    prop: 'stockNum',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['stockNum']}</p>
    }
  },
  {
    label: '权重',
    prop: 'weight',
    minWidth: '100',
    sortable: 'custom',
    customRender(h, row) {
      return <p>{row['weight']}</p>
    }
  },
  {
    label: '首页推荐',
    prop: 'isRecommend',
    minWidth: '100',
    customRender(h, row) {
      return (
        <div>
          <el-switch
            disabled={!this.isShowBtn(this.AUTH_BTN.goods_list_recommend)}
            class="switch"
            onChange={() => this.changeRecommend(row)}
            v-model={row['isRecommend']}
            active-value={true}
            inactive-value={false}
            active-text="打开"
            inactive-text="关闭"
          ></el-switch>
        </div>
      )
    }
  },
  {
    label: '同步渠道',
    prop: 'enableSyncChannel',
    minWidth: '100',
    customRender(h, row) {
      return (
          <div>
            <el-switch
                disabled={!this.isShowBtn(this.AUTH_BTN.goods_list_sync_channel)}
                class="switch"
                onChange={() => this.changeSyncChannel(row)}
                v-model={row['enableSyncChannel']}
                active-value={true}
                inactive-value={false}
                active-text="打开"
                inactive-text="关闭"
            ></el-switch>
          </div>
      )
    }
  },
  {
    label: '状态',
    prop: 'status',
    minWidth: '100',
    customRender(h, row) {
      return (
        <div>
          <el-switch
            disabled={!this.isShowBtn(this.AUTH_BTN.goods_list_status)}
            class="switch"
            onChange={() => this.changeStatus(row)}
            v-model={row['status']}
            active-value={1}
            inactive-value={0}
            active-text="上架"
            inactive-text="下架"
          ></el-switch>
        </div>
      )
    }
  },
  {
    label: '创建时间',
    prop: 'createTime',
    minWidth: '180',
    customRender(h, row) {
      return <p>{row['createTime']}</p>
    }
  },
  {
    label: '操作',
    width: '150',
    fixed: 'right',
    customRender(h, row) {
      return (
        <div>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.goods_list_edit)}
            style="margin-right:10px;"
            underline={false}
            type="primary"
            onClick={() => this.$router.push({ name: 'GoodsEdit', params: { id: row['id'] } })}
          >
            编辑
          </el-link>

          <el-link
              disabled={!this.isShowBtn(this.AUTH_BTN.goods_list_weight)}
              style="margin-right:10px;"
              underline={false}
              type="primary"
              onClick={() => this.handleWeight(row)}
          >
            权重
          </el-link>

          <el-link
            disabled={row['status'] == 1 || !this.isShowBtn(this.AUTH_BTN.goods_list_del)}
            underline={false}
            type="primary"
            onClick={() => this.handleDel(row['id'])}
          >
            删除
          </el-link>
        </div>
      )
    }
  }
]
export default {
  name: 'GoodsList',
  components: { SelectGoodsCategory },
  mixins: [authBtnMixin],
  data() {
    return {
      dialogVisible: false,
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: [],
      searchData: {
        categoryId: '',
        goodsTitle: '',
        status: null,
        goodsCombo: '',
        sortField: '',
        isRecommend: null,
      },
      dialogWeight: false,
      goodsInfo: {},
    }
  },
  provide() {
    return {
      context: this
    }
  },
  activated() {
    this.getGoodsList()
  },
  watch: {
    currentPage() {
      this.getGoodsList()
    },
    pageSize() {
      this.getGoodsList()
    }
  },
  mounted() {
    window['getBpageList'] = () => {
      this.getGoodsList()
    }
  },

  methods: {
    async getGoodsList() {
      const { categoryId, goodsTitle, status, goodsCombo, isRecommend, sortField } = this.searchData
      let params = {
        categoryId,
        goodsTitle,
        status,
        goodsCombo,
        isRecommend,
        sortField,
        page: this.currentPage,
        pageSize: this.pageSize
      }
      const res = await getGoodsListAPI(params)
      this.list = res.list || []
      this.total = res.total
    },
    handleSortChange(column) {
      if (column) {
        let sortWay = column.order == 'ascending' ? '+' : '-';
        this.searchData.sortField = column.prop + sortWay;
        this.getGoodsList()
      }
    },
    // 编辑
    handleEdit() {},
    // 状态
    changeStatus(row) {
      let { id, status } = row
      let data = { id, status: status == 0 ? 0 : 1 }
      let statusDesc = status == 0 ? '下架' : '上架'

      this.$confirm(`此操作将该商品${statusDesc}吗, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          row.status = status == 0 ? 0 : 1
          changeStatusAPI(data).then(() => {
            this.getGoodsList()
            this.$message.success('操作成功')
          })
        })
        .catch(() => {
          row.status = status == 1 ? 0 : 1
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    // 是否推荐
    changeRecommend(row) {
      let { id, isRecommend } = row
      let data = { id, isRecommend }
      let recommendDesc = isRecommend ? '打开': '关闭'

      this.$confirm(`此操作将${recommendDesc}首页推荐, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          row.isRecommend = isRecommend
          changeRecommendAPI(data).then(() => {
            this.getGoodsList()
            this.$message.success('操作成功')
          })
        })
        .catch(() => {
          row.isRecommend = !isRecommend
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    // 是否同步渠道
    changeSyncChannel(row) {
      let { id, enableSyncChannel } = row
      let data = { id, enableSyncChannel }
      let enableSyncChannelDesc = enableSyncChannel ? '打开': '关闭'

      this.$confirm(`此操作将${enableSyncChannelDesc}同步渠道, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            row.enableSyncChannel = enableSyncChannel
            changeSyncChannelAPI(data).then(() => {
              this.getGoodsList()
              this.$message.success('操作成功')
            })
          })
          .catch(() => {
            row.enableSyncChannel = !enableSyncChannel
            this.$message({
              type: 'info',
              message: '已取消操作'
            })
          })
    },
    // 删除
    handleDel(id) {
      this.$confirm('此操作将永久删除该商品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteGoodsAPI(id).then(() => {
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
            this.getGoodsList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 详情
    handleStorage({ id }) {},

    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.$refs.goodsTable.handleSortClear()
      this.getGoodsList()
    },
    // 套餐类型
    filterCombo(arr) {
      let name = ''
      arr
        .map(item => {
          return item.enumText
        })
        .join()
      return name
    },
    handleWeight(row) {
      this.goodsInfo = row
      this.dialogWeight = true
    },
    submitWeight() {
      let data = {
        id: this.goodsInfo.id,
        weight: this.goodsInfo.newWeight,
      }
      changeWeightAPI(data).then(() => {
        this.$message.success('操作成功')
        this.getGoodsList()
        this.dialogWeight = false
        this.goodsInfo = {}
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;

  .header-search {
    margin-top: 20px;
  }

  .header-tabs {
    margin-bottom: 20px;
  }

  .el-divider--horizontal {
    margin: 0 0 20px;
  }
  /* switch按钮样式 */
  .switch .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }
  /*打开时文字位置设置*/
  .switch .el-switch__label--right {
    z-index: 1;
  }
  /* 调整打开时文字的显示位子 */
  .switch .el-switch__label--right span {
    margin-left: 5px;
  }
  /*关闭时文字位置设置*/
  .switch .el-switch__label--left {
    z-index: 1;
  }
  /* 调整关闭时文字的显示位子 */
  .switch .el-switch__label--left span {
    margin-left: 20px;
  }
  /*显示文字*/
  .switch .el-switch__label.is-active {
    display: block;
  }
  /* 调整按钮的宽度 */
  .switch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}
</style>
